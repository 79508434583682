<template>
  <v-row
    class="fill-height"
    justify="center"
    align="center"
    align-content="center"
  >
    <div
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
      "
    >
      <canvas
        id="confettiCanvas"
        ref="confettiCanvas"
        class="sticky"
        style="z-index: 5; pointer-events: none"
        :width="pageWidth"
        :height="pageHeight"
      >
      </canvas>
    </div>
    <v-col
      v-if="!loadingComplete"
      cols="auto"
      class="text-h3 primary--text text-center"
    >
      <dots-loader
        class="mb-8"
        :size="120"
        :color="$vuetify.theme.currentTheme.primary"
        style="margin-left: auto; margin-right: auto"
      />
      <p v-if="loaderTimeLeft >= 1500" class="fade-in">
        {{ $t("emailPainCheckIn.loading.savingLevel") }}
      </p>
      <div v-else>
        <p class="fade-in">
          {{ $t("emailPainCheckIn.loading.updatingProgramme") }}
        </p>
      </div>
    </v-col>
    <v-col
      v-else
      cols="12"
      sm="10"
      md="5"
      lg="4"
      class="text-center"
      align-self="center"
    >
      <p class="text-h3 primary--text">
        {{ $t("emailPainCheckIn.title") }}
      </p>
      <p class="text-h6 py-8">
        {{ $t("emailPainCheckIn.text") }}
      </p>
      <v-btn color="secondary" @click="goToDashboard()">{{
        $t("emailPainCheckIn.button")
      }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { submitEmailPainCheckInValues } from "@/customApi";
import { mapMutations } from "vuex";
import DotsLoader from "@/components/common/loaders/DotsLoader.vue";
import VueConfetti from "vue-confetti";
import Vue from "vue";
Vue.use(VueConfetti);

export default {
  components: {
    DotsLoader
  },
  data() {
    return {
      success: true,
      submitted: false,
      timeInterval: null,
      loaderTimeLeft: 3000,
      pageWidth: 0,
      pageHeight: 0,
      urlParams: {}
    };
  },
  async mounted() {
    this.setWellnessRedirect(true);
    await this.getUrlParameters();
    this.startTimer();
    await this.submitEmailPainCheckin();
  },
  computed: {
    loadingComplete() {
      return this.loaderTimeLeft <= 0 && this.submitted;
    }
  },
  methods: {
    ...mapMutations(["setWellnessRedirect"]),
    async getUrlParameters() {
      let paramString = window.location.href.split("?")[1];
      if (!paramString) {
        return;
      }
      paramString = paramString.replace("#/", "");
      let queryString = new URLSearchParams(paramString);

      for (var pair of queryString.entries()) {
        this.urlParams[pair[0]] = pair[1];
      }
    },
    goToDashboard() {
      if (this.urlParams.teamid && this.urlParams.idp) {
        this.$router.replace({
          path: "/",
          query: { teamid: this.urlParams.teamid, idp: this.urlParams.idp }
        });
        return;
      }

      this.$router.replace("/", () => {});
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.loaderTimeLeft -= 10;
        if (this.loadingComplete) {
          clearInterval(this.timerInterval);
          this.startConfetti();
        }
      }, 10);
    },
    async submitEmailPainCheckin() {
      try {
        if (
          this.urlParams.painCheckinId &&
          this.urlParams.painLevel &&
          this.urlParams.painArea
        ) {
          let dto = {
            endUserId: this.urlParams.painCheckinId,
            painLevel: Number(this.urlParams.painLevel),
            painArea: this.urlParams.painArea
          };
          await submitEmailPainCheckInValues(dto);
        }
      } catch (ex) {
        this.success = false;
      } finally {
        this.submitted = true;
      }
    },
    async startConfetti() {
      this.pageHeight = window.innerHeight;
      this.pageWidth = window.innerWidth;

      this.$confetti.start({
        canvasElement: this.$refs.confettiCanvas,
        particlesPerFrame: 1.25,
        particles: [
          {
            type: "rect"
          }
        ]
      });

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);
    }
  }
};
</script>

<style scoped>
.fade-in {
  -webkit-animation: fadeIn 1.5s;
  -moz-animation: fadeIn 1.5s;
  -o-animation: fadeIn 1.5;
  -ms-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
