var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "fill-height",
      attrs: { justify: "center", align: "center", "align-content": "center" }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            top: "0",
            height: "100%",
            width: "100%",
            "pointer-events": "none"
          }
        },
        [
          _c("canvas", {
            ref: "confettiCanvas",
            staticClass: "sticky",
            staticStyle: { "z-index": "5", "pointer-events": "none" },
            attrs: {
              id: "confettiCanvas",
              width: _vm.pageWidth,
              height: _vm.pageHeight
            }
          })
        ]
      ),
      !_vm.loadingComplete
        ? _c(
            "v-col",
            {
              staticClass: "text-h3 primary--text text-center",
              attrs: { cols: "auto" }
            },
            [
              _c("dots-loader", {
                staticClass: "mb-8",
                staticStyle: { "margin-left": "auto", "margin-right": "auto" },
                attrs: {
                  size: 120,
                  color: _vm.$vuetify.theme.currentTheme.primary
                }
              }),
              _vm.loaderTimeLeft >= 1500
                ? _c("p", { staticClass: "fade-in" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("emailPainCheckIn.loading.savingLevel")) +
                        " "
                    )
                  ])
                : _c("div", [
                    _c("p", { staticClass: "fade-in" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("emailPainCheckIn.loading.updatingProgramme")
                          ) +
                          " "
                      )
                    ])
                  ])
            ],
            1
          )
        : _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: {
                cols: "12",
                sm: "10",
                md: "5",
                lg: "4",
                "align-self": "center"
              }
            },
            [
              _c("p", { staticClass: "text-h3 primary--text" }, [
                _vm._v(" " + _vm._s(_vm.$t("emailPainCheckIn.title")) + " ")
              ]),
              _c("p", { staticClass: "text-h6 py-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("emailPainCheckIn.text")) + " ")
              ]),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.goToDashboard()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("emailPainCheckIn.button")))]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }